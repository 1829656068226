
<template>
  <div class="m-3">
    <article class="tile is-child box p-2">
      <div class="is-flex is-align-items-center mb-3 is-justify-content-space-between">

        <div class="is-flex is-align-items-center">

          <span class="px-3 is-size-4 has-text-weight-bold">Quote Items</span>
          <!-- vertical break -->
          <!-- <div class="is-divider-vertical" /> -->

          <div class="is-flex  ml-2">
            <span class="mr-2">Items:</span>
            <span class="has-text-success has-text-weight-bold">
              {{ $filters.formatCurrency( totalQuoteItems, $userInfo.locale, false, 2) }}
            </span>
          </div>

          <div class="is-flex ml-3"
            v-if="totalQuoteItems != value.totalExGst">
            <span class="mr-2">Grand Total:</span>
            <div class="has-text-success has-text-weight-bold is-underlined is-clickable"
              @click="handleSummaryClick">
              <span class="">
                {{ $filters.formatCurrency( value.totalExGst, $userInfo.locale, false, 2) }}
              </span>
            </div>
          </div>

        </div>
        <div class="is-flex">
          <div class="is-flex is-align-items-center is-size-5 mr-4"
            :class="{'disabled' : isAvailableItemsOpen}">

            <div class="buttons has-addons">
              <button class="button"
                @click="selectedViewType = viewTypes.singleColView"
                :class="selectedViewComputed == viewTypes.singleColView ? 'is-selected has-text-primary': 'has-text-grey'">
                <span class="mdi mdi-view-split-horizontal mdi-24px mr-1" />
                <span>Full</span>
              </button>
              <button class="button"
                @click="selectedViewType = viewTypes.multiColView"
                :class="selectedViewComputed == viewTypes.multiColView ? 'is-selected has-text-primary': 'has-text-grey'">
                <span class="mdi mdi-view-split-vertical mdi-24px mr-1" />
                <span>Dual</span>
              </button>
              <button class="button"
                @click="selectedViewType = viewTypes.tabbedView"
                :class="selectedViewComputed == viewTypes.tabbedView ? 'is-selected has-text-primary': 'has-text-grey'">
                <span class="mdi mdi-tab mdi-24px mr-1" />
                <span>Tabs</span>
              </button>
            </div>
          </div>

          <button v-if="value.subQuoteNo > 0"
            class="button is-success tooltip is-tooltip-topright"
            data-tooltip="Adjust Main Quote Items"
            @click="handleToggleAvaliableMainItems">
            <span class="icon">
              <i class="mdi"
                :class="{ 'mdi-adjust' : !isAvailableMainActive, 'mdi-close' : isAvailableMainActive }" />
            </span>
            <span>{{ isAvailableMainActive ? 'Done' : 'Adjust' }}</span>
          </button>

          <button class="button is-primary mx-4"
            @click="handleToggleAvaliableItems"
            :disabled="value.readOnly">
            {{ isAvailableItemsOpen ? '- Close' : '+ Add' }}
          </button>
        </div>
      </div>

      <div class="grid">

        <!------------------------- Available Item  -------------------------->
        <div v-if="!!isAvailableItemsOpen || !!isAvailableMainActive"
          class="mb-4">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': avaliableItemsType == 'LABOUR'}"
                @click="avaliableItemsType = 'LABOUR'">
                <a>LABOUR</a>
              </li>
              <li :class="{'is-active': avaliableItemsType == 'OTHER'}"
                @click="avaliableItemsType = 'OTHER'">
                <a>MECH</a>
              </li>
              <li :class="{'is-active': avaliableItemsType == 'PARTS'}"
                @click="avaliableItemsType = 'PARTS'">
                <a>PARTS</a>
              </li>
              <li :class="{'is-active': avaliableItemsType == 'MISC'}"
                @click="avaliableItemsType = 'MISC'">
                <a>MISC.</a>
              </li>
              <li :class="{'is-active': avaliableItemsType == 'SUBLETS'}"
                @click="avaliableItemsType = 'SUBLETS'">
                <a>SUBLETS</a>
              </li>
            </ul>
          </div>

          <add-items-component :value="value"
            :vehicle="vehicle"
            :next-line-number="nextLineNumber"
            :is-available-items-active="isAvailableItemsOpen"
            :avaliable-items-type="avaliableItemsType"
            :is-main-active="isAvailableMainActive"
            @handleUpdate="handleUpdate" />
        </div>

        <div :class="{'scrollable-part-items': isAvailableItemsOpen}">
          <div class="tabs"
            v-if="selectedViewType == viewTypes.tabbedView && !isAvailableItemsOpen">
            <ul>
              <li :class="{'is-active': selectedTab.id == tab.id}"
                v-for="(tab) in dataTypes"
                :key="tab.title"
                @click="selectedTab = tab">
                <a>
                  <span>{{ tab.title }}</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- large/multi row/tab view -->
          <quote-builder-items-view :selected-view-type="selectedViewComputed"
            :selected-tab="selectedTab"
            :view-types="viewTypes"
            :value="value"
            :is-available-items-open="isAvailableItemsOpen"
            :avaliable-items-type="avaliableItemsType"
            :is-present-as-modal-active="isPresentAsModalActive"
            :next-line-number="nextLineNumber"
            @onOpgCodeChanged="$emit('onOpgCodeChanged')"
            @handleUpdate="handleUpdate"
            @handlePresentAs="handlePresentAs"
            :vehicle="vehicle" />
        </div>
      </div>
    </article>

    <quote-item-present-as-modal v-if="isPresentAsModalActive"
      :active.sync="isPresentAsModalActive"
      v-model="item.presentAs"
      :maximum-options="2"
      @ok="closePresentAsModal()"
      @cancel="closePresentAsModal()"
      @close="closePresentAsModal()">
      <p slot="text-title">Present As</p>
    </quote-item-present-as-modal>

  </div>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import { ItemCategoryTypes } from '@/enums'
import QuoteBuilderItemsView from './QuoteBuilderItemsView.vue'
import { QuoteItemPresentAsModal } from '@/components/BulmaModal'
import StoreUtil from '@/store/utils'
import AddItemsComponent from './components/AddItemsComponent.vue'
import cloneDeep from 'lodash.clonedeep'
import { QuoteTotalsMethodMixin } from './mixins'
import QuoteRoutes from '../route-types'
import deepDiff from 'deep-diff'

export default {
  name: 'QuoteBuilderView',
  components: { QuoteBuilderItemsView, QuoteItemPresentAsModal, AddItemsComponent, QuoteItemPresentAsModal, QuoteItemPresentAsModal },
  mixins: [QuoteTotalsMethodMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    vehicle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      innerValue: null,
      isAvailableItemsOpen: false,
      isAvailableMainActive: false,
      avaliableItemsType: 'LABOUR',
      dataTypes: [
        { id: 0, key: 'RR', title: 'Remove & Refit', source: 'labours', type: ItemCategoryTypes.RR, source: 'labours' },
        { id: 2, key: 'Repair', title: 'Repair', source: 'labours', type: ItemCategoryTypes.REP, source: 'labours' },
        { id: 3, key: 'Paint', title: 'Paint', source: 'labours', type: ItemCategoryTypes.PAINT, source: 'labours' },
        { id: 4, key: 'MECH', title: 'Mechanical', source: 'others', type: ItemCategoryTypes.MECH, source: 'labours' },
        { id: 5, key: 'Parts', title: 'Parts', source: 'parts', type: ItemCategoryTypes.PART, source: 'parts' },
        { id: 6, key: 'Misc', title: 'Miscellaneous', source: 'miscs', type: ItemCategoryTypes.MISC, source: 'misc' },
        { id: 7, key: 'Sublets', title: 'Sublets', source: 'sublets', type: ItemCategoryTypes.SUBL, source: 'sublets' }
      ],
      totals: {
        parts: 0,
        labours: 0,
        others: 0,
        miscs: 0,
        sublets: 0
      },
      // view types for the quote items
      viewTypes: {
        singleColView: 'singleCol',
        multiColView: 'multiCol',
        tabbedView: 'tabView'
      },
      selectedViewType: 'singleCol',
      //just used for tabbed view
      selectedTab: { id: 0, title: 'Remove && Refit', source: 'labours', type: ItemCategoryTypes.RR },
      isPresentAsModalActive: false,
      presentAsItem: null
    }
  },
  computed: {
    routeTypes() {
      return QuoteRoutes
    },
    selectedViewComputed() {
      // return single column view
      if (!this.isAvailableItemsOpen) return this.selectedViewType
      return this.viewTypes.singleColView
    },
    totalQuoteItems() {
      const clonedValue = cloneDeep(this.value)
      return (
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.labours), ItemCategoryTypes.RR) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.labours), ItemCategoryTypes.REP) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.labours), ItemCategoryTypes.PAINT) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.others), ItemCategoryTypes.MECH) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.parts), ItemCategoryTypes.PART) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.miscs), ItemCategoryTypes.MISC) +
        this.calculateItemCategoryTotal(clonedValue.quotingMethod, cloneDeep(clonedValue.sublets), ItemCategoryTypes.SUBL)
      )
    }
  },
  watch: {
    selectedViewType(val) {
      // check if value matches any of the view types
      if (Object.values(this.viewTypes).includes(val)) {
        StoreUtil.setLocalStorage('quoteBuilder', 'selectedViewType', val)
      }
    },
    value: {
      handler(newVal, oldVal) {
        this.innerValue = _cloneDeep(newVal)
        // Is this really the best way to do this?
        // I was thinking of maybe running these based on the category that was updated
        // but i think the problem with that is we risk maintaining the sync between the categories
        // if you update one category, you have to update the others as well
        // otherwise when you update the other categories, the values will be out of sync
        if (deepDiff(newVal, oldVal)) {
          this.$nextTick(() => {
            this.$eventHub.$emit('updateLabour')
            this.$eventHub.$emit('updateOthers')
            this.$eventHub.$emit('updateParts')
            this.$eventHub.$emit('updateMiscs')
            this.$eventHub.$emit('updateSublets')
          })
        }
      },
      deep: true
    },

    innerValue: {
      handler(newVal, oldVal) {
        if (deepDiff(newVal, oldVal)) {
          this.$nextTick(() => {
            this.$eventHub.$emit('updateLabour')
            this.$eventHub.$emit('updateOthers')
            this.$eventHub.$emit('updateParts')
            this.$eventHub.$emit('updateMiscs')
            this.$eventHub.$emit('updateSublets')
          })
        }
      },
      deep: true
    }
  },
  created() {
    // get selected view type from local storage
    const selectedViewType = StoreUtil.getLocalStorage('quoteBuilder', 'selectedViewType')
    if (selectedViewType) {
      this.selectedViewType = selectedViewType
    }
  },
  methods: {
    handleSummaryClick() {
      this.$router.push({ name: this.routeTypes.QuoteSummary.name, params: { quoteId: this.$route.params.quoteId }, query: this.$route.query })
    },
    handleToggleAvaliableMainItems() {
      this.isAvailableItemsOpen = false
      this.isAvailableMainActive = !this.isAvailableMainActive
    },
    handleToggleAvaliableItems() {
      this.isAvailableMainActive = false
      this.isAvailableItemsOpen = !this.isAvailableItemsOpen
    },
    handleUpdate(value) {
      this.$emit('input', value)
    },
    closePresentAsModal() {
      this.$nextTick(() => {
        this.isPresentAsModalActive = false
      })
    },
    handlePresentAs(item) {
      if (item) {
        this.item = item
        this.isPresentAsModalActive = true
      }
    }
  }
}
</script>

<style lang="scss" >
.full-quote-page-height {
  height: fit-content;
  // 52px for the navbar and 52px for the quote header, 20px for the padding
  min-height: calc(100vh - 52px - 52px - 20px) !important;
}

.cell {
  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(6, 108, 224);
  }

  .row {
    background-color: #eeeeee;
    border: 1px solid #000000;
    padding: 10px;
    margin: 4px;
    border-radius: 5px;
  }
}

.scrollable-part-items {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 79vh;

  .quote-item-heading {
    position: sticky;
    top: 0px;
    z-index: 9;
  }
}
</style>